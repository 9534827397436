import React from 'react';
import { FaGithub, FaInstagram} from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p>© 2023 wjxor. All rights reserved.</p>
      <div className="social-icons">
        <a href="https://github.com/wjxor" target="_blank" rel="noopener noreferrer">
          <FaGithub/>
        </a>
        <a href="https://www.instagram.com/sanghyeob_00/" target="_blank" rel="noopener noreferrer">
            <FaInstagram/>
        </a>
        {/* Add more icons as needed */}
      </div>
    </div>
  );
}

export default Footer;
