import React, { useEffect, useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';
import './App.css';
import Footer from './Footer';

const BACKEND_ENDPOINT = 'https://3tp5movkoid7z6kfknjl4rpnku0icndi.lambda-url.ap-northeast-2.on.aws/';

function App() {
  const [longUrl, setLongUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [error, setError] = useState('');
  

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
  
    if (longUrl.length < 5) {
      setShortUrl('');
      setError('Please enter a valid URL'); // Set the error message
      return;
    }

    try {
      // Send a POST request to the backend using Axios
      const response = await axios.post(`${BACKEND_ENDPOINT}/shorten`, {url:longUrl});
      const { shortUrl } = response.data;
      setShortUrl(shortUrl);
      setError('');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const copyToClipboard = () => {
    // Create a temporary textarea to copy the text
    const el = document.createElement('textarea');
    el.value = "juso.it/" + shortUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  
    // Optionally: Show a feedback to users to indicate the text has been copied
    alert("URL Copied to Clipboard!");
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path !== '/') {
      const key = path.substring(1);
  
      axios.get(`${BACKEND_ENDPOINT}/${key}`) // Use just BACKEND_ENDPOINT for GET
        .then(response => {
          const { data } = response;
          const targetURL = data.targetURL;
  
          console.log(targetURL + ' received');
  
          window.location.href = `https://${targetURL}`;

        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  });

  return (
    <div className="App">
      <h1>URL Shortener</h1>
      <form onSubmit={handleSubmit}>
        <input
            type="text"
            value={longUrl}
            onChange={(event) => setLongUrl(event.target.value)}
            placeholder='Enter a long URL'
        />
        <button type='submit'>Shorten</button>
      </form>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {shortUrl && (
        <div>
          <p>Shortened URL:</p>
          <a href={shortUrl} target="_blank" rel="noopener noreferrer">
            {"juso.it/" + shortUrl}
          </a>
          <FaCopy className="copy-icon" onClick={copyToClipboard} /> 
        </div>
      )}

      <Footer></Footer>
    </div>
  )
}

export default App;